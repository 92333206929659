import React from 'react';
import './App.css';
import './App.js';
import SocialMediaList from './SocialMediaList';


    
export default function Footer() {

 return (
    <div>
    <SocialMediaList/>
    </div>
   
 )};
