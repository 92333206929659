import './App.css';
import  Header from './Header.js'
import Footer from './Footer';
import React from 'react'

function App() {
  return (
    <div className="App">
      <Header/>
      <Footer/>
    </div>
  );
}

export default App;
